.base-body-container {
  overflow-y: auto !important;
}

.icon-style {
  display: block;
  text-indent: -9999px;
  width: 27px;
  height: 37px;
}

.icon-style-optional {
  @extend .icon-style;
  opacity: 50%;
}

.icon-style-user {
  @extend .icon-style;
  background: url(/assets/images/user.svg);
  background-size: 27px 37px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.icon-style-at {
  @extend .icon-style;
  background: url(/assets/images/at.svg) no-repeat center center;
  background-size: 27px 37px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.icon-style-company {
  @extend .icon-style;
  width: 35px;
  height: 30px;
  background: url(/assets/images/company.svg) no-repeat center center;
  background-size: 35px 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.icon-style-phone {
  @extend .icon-style;
  width: 21px;
  height: 33px;
  background: url(/assets/images/phone.svg) no-repeat center center;
  background-size: 21px 33px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.icon-style-lock {
  @extend .icon-style;
  background: url(/assets/images/lock.svg) no-repeat center center;
  background-size: 27px 37px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.button-full {
  background: #0066cc;
  border-radius: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
}

.button-empty {
  background: #0066cc;
  border-radius: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
}

.text-caption {
  color: #00aee8;
  text-align: center;
}

@import '../node_modules/@syncfusion/ej2-angular-popups/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-angular-notifications/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-querybuilder/styles/bootstrap5.css';
// @import '~codemirror/lib/codemirror';
// @import '~codemirror/theme/material';
// @import '~codemirror/theme/eclipse';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

.asol-input.e-input-group {
  background-color: white;
  height: 4rem;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.e-input-group.e-control-wrapper.e-ddl {
  background-color: white !important;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-content: center;
  border-collapse: collapse !important;
  border: 0 !important;
}

.asol-select.e-combobox .e-input-group input.e-input {
  height: 2.5rem;
}

.e-dialog .e-dlg-content {
  padding: 4.375rem !important;

  .dialog-header > h1 {
    font-family: 'GeomanistMedium' !important;
    font-weight: 700;
  }
}

.app-loader-overlay {
  background: rgba(255, 255, 255, 0.8);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0px;
  left: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.app-loader-spinner {
  animation: spin-anim 1s linear infinite;
  animation-timing-function: ease;
  opacity: 1;
  height: 6rem;
  width: 6rem;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
